export const fbqEvent = (id, event, options = {}) => {
  try {
    if (!!window.fbq && id) {
      const isInitialized = window.fbq
        .getState()
        .pixels.find((pixel) => pixel.id === id)
      if (!isInitialized) {
        window.fbq('init', id)
      }
      window.fbq('trackSingle', id, event, options)
    }
  } catch (err) {
    setTimeout(() => fbqEvent(id, event, options), 1000)
  }
}
