
import { mapGetters } from 'vuex'
export default {
  name: 'Empty',
  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    return {
      ...i18nSeo,
      title: this.$t('meta.title'),
      bodyAttrs: {
        class: !this.cookieConsent && !this.$config.WIDGET ? 'ofh' : '',
        ...i18nSeo.bodyAttrs
      }
    }
  },
  computed: {
    ...mapGetters({
      cookieConsent: 'cookies/consent'
    })
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch (e) {
      console.log(e)
    }
  }
}
