export const tiktokEvent = (id, event, options = {}, email = null) => {
  try {
    if (!!window.ttq && id) {
      const isInitialized = (window.ttq.instances() || []).find(
        (pixel) => pixel.pixelCode === id
      )

      if (!isInitialized) {
        window.ttq.load(id)
      }

      if (email) {
        window.ttq.instance(id).identify({ email })
      }

      window.ttq.instance(id).track(event, options)
    }
  } catch (err) {
    console.log(err)
    setTimeout(() => tiktokEvent(id, event, options), 1000)
  }
}
